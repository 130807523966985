html{

  scroll-behavior: smooth;
}
body {
  font-family: rialto-piccolo-df, serif;
  font-weight: 400;
  font-style: normal;
  margin: 0;
  background-image: url("images/pagebg.png");
  background-size: cover;
}
p {
  font-size: 24px;
  text-align: justify;
  text-justify: inter-word;
}
h1,
h2,
h3 {
  font-family: hwt-artz, sans-serif;
  text-align: center;
  color: white;
  text-shadow: 3px 2px black;
}

h2 {
  font-size: 4em;
}

#container {
  max-width: 900px;
  margin: auto;
  position: relative;
}

/*navigation menu */
.topnav {
  position: fixed;
  height: 80px;
  width: 100%;
  max-width: 900px;
  z-index: 99;
}

.topnav #menu {
  background-image: url("images/menubg.png");
  background-size: cover;
  background-position: bottom center;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 120px;
  font-family: hwt-artz, sans-serif;
  min-height: 50vh;
}

.topnav a {
  color: white;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 2em;
  display: block;
  opacity: 0.5;
}

.topnav a.icon {
  display: block;
  position: absolute;
  right: 0;
  width: 20px;
  top: 0;
  opacity: 1;
}

.topnav a:hover {
  opacity: 1;
  text-decoration: underline;
  text-shadow: 2px 2px black;
}

.active {
  opacity: 1;
  text-decoration: underline;
  text-shadow: 2px 2px black;
}

/* nav burger */
.bar {
  content: "";
  width: 100%;
  padding: 2px;
  background: white;
  margin: 5px 0;
  display: block;
  transition: all 0.5s;
}

.icon.open > .bar.two {
  display: none;
  transition: all 0.5s;
}

.icon.open > .bar.one {
  transform: rotate(45deg);
  margin-top: 15px;
  transition: all 0.5s;
}

.icon.open > .bar.three {
  transform: rotate(-45deg);
  margin-top: -9px;
  transition: all 0.5s;
}

/*-- Hero image secion ---*/
.heroimg {
  width: 100%;
  max-width: 100%;
}

.hero {
  background-image: url("images/mintbg.png");
  background-size: cover;
}

.holder {
  display: flex;
  align-items: top;
  justify-content: space-between;
  margin: 0 10%;
  height: 0;
}

.holder a {
  width: 20%;
  height: auto;
}

.holder a img {
  width: 100%;
}

.gravestone {
  width: 50%;
  margin: 0 25% -15px 25%;
}

/*--- Mint --*/

#mint {
  background: black;
  font-family: hwt-artz, sans-serif;
  color: white;
  text-align: center;
  margin-top: -5px;
  padding-top: 20px;
}

#mint p{
  margin: 20px;
  font-family: rialto-piccolo-df, serif;
  font-size: 1.4em;
}

#mint a{
  color: red;
  font-weight: bold;
}
.time {
  display: flex;
  align-items: center;
  justify-content: center;
}

.time > div {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  font-size: 4em;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-shadow: 2px 3px white;
}

.days {
  color: #83000e;
}
.hours {
  color: #cf0000;
}
.minutes {
  color: #ff6000;
}
.seconds {
  color: #f7d42d;
}

sub {
  font-size: 14px;
  text-shadow: 0px 0px black;
  color: white;
  text-align: center;
  font-family: 'rialto-piccolo-df', serif;
}

.bottom-wave {
  position: relative;
  height: 70px;
  width: 100%;
  background: black;
}
.bottom-wave:before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 60%;
  height: 80px;
  background-color: black;
  right: 0;
  top: 8px;
}
.bottom-wave:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 50%;
  height: 70px;
  background-color: black;
  left: 0;
  top: 27px;
}

/*---- Origins -----*/
#origins {
  background-image: url("images/originsbg.png");
  min-height: 200px;
  background-size: cover;
  padding: 30px 10%;
  color: white;
}

/*---- About ----*/

#about {
  background: #fff4c9;
  color: #585443;
  padding: 30px 10%;
  z-index: 8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#about img {
  width: 200px;
  height: auto;

}

#about h2 {
  color: #585443;
  text-shadow: 1px 2px white;
}

/*--- Roadmap ---*/

#roadmap {
  background: #00ffa2;
  color: #585858;
  padding: 30px 10%;
  z-index: 8;
}

#roadmap h2 {
  color: #000000;
  opacity: 0.6;
  text-shadow: 1px 2px white;
}

.phantoms {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.phantom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.phantom img {
  width: 80%;
  max-width: 300px;
  border-radius: 50%;
  border: 4px solid black;
}

.blackbox{
  background: black;
  padding: 50px 0;  
  display: flex;
  flex-direction: column;
}

.roadmapimg{
  height: 200px;
  width: 200px;
  margin: 0 auto -150px;
}

.maroonbox{
  background: #530001;
  padding: 100px 0 50px; 

}

.maroonbox p{
  color: white;
  text-align: center;
}

.octoberbox{
  background: #83000e;
  padding: 50px 0; 

}

.octoberbox p{
  color: white;
  text-align: center;
}

.novemberbox{
  background: #cf0000;
  padding: 50px 0; 

}

.novemberbox p{
  color: white;
  text-align: center;
}

.decemberbox{
  background: #ff6000;
  padding: 50px 0; 

}

.decemberbox p{
  color: white;
  text-align: center;
}

.twentytwobox{
  background: black;
  padding: 50px 0; 

}

.twentytwobox p{
  color: white;
  text-align: center;
}

#team {
  background: #fff4c9;
  color: #585443;
  padding: 30px 10%;
  z-index: 8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#team p{
  text-align: center;
}

#team img{
  width: 200px;
  height: auto;
  margin: auto;
}

#faq {
  background: #00ffa2;
  color: #585858;
  padding: 30px 10%;
  z-index: 8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#faq img{
  width: 200px;
  height: auto;
  margin: auto;
}

#faq h2 {
  color: #000000;
  opacity: 0.6;
  text-shadow: 1px 2px white;
}

#footer{
  background: black;
}

#footer p{
  color: white;
  text-align: center;
  margin: 0;
  padding-bottom: 50px;
}

.footerholder {
  background-color: #000000;
  display: flex;
  align-items: top;
  justify-content: center;
  padding: 10%;
}

.footerholder a {
  width: 20%;
  height: auto;
  margin: 20px;
}

.footerholder a img {
  width: 100%;
}

.circle{
  height: 200px;
  width: 200px;
  border-radius: 50%;
}

#team h2{
  margin-bottom: 5px;
}

#team p{
  margin-top: 0;
}

h4 a{
  color: inherit;
  font-size: 0.7em;
  margin-top: 0 !important;
  font-size: 1.8em;
  font-family: hwt-artz, sans-serif;
  text-align: center;
  color: white;
  text-shadow: 3px 2px black;
  text-decoration: none;
}

#mint p.center{
  text-align: center;
  font-family: hwt-artz, sans-serif;
  text-align: center;
  color: red;
  font-size: 3em;
}




/*MODAL*/
/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

  .close:hover,
  .close:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
  }
/* Modal Header */
.modal-header {
  padding: 2px 16px;
  background-color: #222;
  color: white;
}
  .modal-header h2 {
      font-size: 1rem;
  }

/* Modal Body */
.modal-body {
  padding: 2px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Modal Footer */
.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: black;
  margin: 2vh auto auto auto;
  padding: 0;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  animation-name: animatetop;
  animation-duration: 0.4s;
  text-align: center;
  max-width: 700px;
}



/* Add Animation */
@keyframes animatetop {
  from {
      top: -400px;
      opacity: 0
  }

  to {
      top: 0;
      opacity: 1
  }
}

.modalcont{
  word-wrap: break-word;
  max-width: 90%;
  margin: auto;
  text-align: center;
}

.button-minus, .button-plus{
 padding: 10px;
 font-size: 1em;
}

.quantity-field{
  padding: 10px;
  background: black;
  border: 2px white solid;
  font-size: 1em;
  color: white;
}
.modalcont button{
  background: white;
  color: black;
  padding: 10px 15px;
  border: 0;
  font-size: 1em;
  margin-top: 5px;
}

#button-cta{
  background: white;
  border:0;
  color: black;
  font-size: 1.7em;
  padding: 10px 30px;
  margin: auto;
}

button:disabled{
  background: grey; /*as fuck*/
}

.rightimg{
  position: absolute;
  right: 20px;
}
.leftimg{
  position: absolute;
  left: 20px;
}